<template>
  <div>
    <b-row class="match-height">
      <div class="col-md-7">
        <b-card
          style="padding: 0px !important;"
          bg-variant="transparent"
          class="card-img-top"
          :img-src="require('@/assets/images/gamificacao/banner.jpg')"
          img-alt="Profile Cover Photo"
          no-body
        />
      </div>
      <div class="col-md-5 mobile_score">
        <b-card
          class="gradient-background"
          text-variant="white"
          style="padding: 5px;"
        >
          <div
            id="div_score"
            style="display: inline-block;"
          >
            <b-card-title
              class="text-white"
              style="font-size: 30px;"
            >
              Bem vindo {{ user.name }}
            </b-card-title>
            <b-badge
              variant="success"
              style="font-size: 20px;"
              class="badge_score"
            >
              <div
                id="green_descrition"
                class="big_descrition"
              >
                4880{{ userStats.score }}
              </div> <div class="mini_text">
                Pontos
              </div>
            </b-badge>
            <br>
            <b-badge
              style="font-size: 20px;"
              class="badge_score badge_trasnparent"
            >
              <div class="big_descrition">
                13h{{ userStats.totalTimeWatched }}
              </div> <div
                class="mini_text"
                style="max-width: 100px;"
              >
                Total assistido
              </div>
            </b-badge>
            <br>
            <b-badge
              style="font-size: 20px;"
              class="badge_score badge_trasnparent"
            >
              <div class="big_descrition">
                28{{ userStats.countLiveWatched }}
              </div> <div
                class="mini_text"
                style="max-width: 110px;"
              >
                Aulas assistidas
              </div>
            </b-badge>
            <br>
            <b-badge
              style="font-size: 20px;"
              class="badge_score badge_trasnparent"
            >
              <div
                class="big_descrition"
              >
                5{{ userStats.consecutive }}
              </div><div
                class="mini_text"
                style="margin-left: 13px; padding-top: 2px;"
              >
                Acessos consecutivos
              </div>
            </b-badge>
          </div>
          <div
            id="div_img_score"
            class="img_score"
          >
            <img
              src="../../../assets/images/gamificacao/mestre_simposios_badge.png"
              style="max-width: 300px"
            >
            <p style="text-align: center; color: white; font-size: 21px; margin-top: 65px;">
              <b>
                Mestre dos Simpósios
              </b>
            </p>
          </div>
        </b-card>
      </div>
    </b-row>
    <b-row class="match-height">
      <div class="col-md-4">
        <b-card
          no-body
          class="card-employee-task"
        >
          <b-card-header>
            <b-card-title>Ranking geral</b-card-title>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="cursor-pointer"
            />
          </b-card-header>

          <!-- body -->
          <b-card-body>
            <div
              v-for="(player) in ranking"
              :key="player.id"
              class="employee-task d-flex justify-content-between align-items-center"
            >
              <b-media no-body>
                <b-media-aside class="mr-75">
                  <b-avatar
                    rounded
                    size="42"
                    :src="validationImage(player.user.photo)"
                  />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0">
                    {{ player.user.name }}
                  </h6>
                </b-media-body>
              </b-media>
              <div class="d-flex align-items-center">
                <b-badge
                  variant="success"
                  style="min-width: 115px; min-height: 30px; font-size: 15px;"
                >
                  {{ player.score }} pontos
                </b-badge>
                <!-- chart -->
                <!--
                <vue-apex-charts
                  type="radialBar"
                  height="30"
                  width="30"
                  :options="chartData[index].options"
                  :series="chartData[index].series"
                />-->
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card
          no-body
          class="card-employee-task"
        >
          <b-card-header>
            <b-card-title>Conquistas</b-card-title>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="cursor-pointer"
            />
          </b-card-header>

          <!-- body -->
          <b-card-body>
            <div
              class="d-flex justify-content-between align-items-center"
              style="background-color: #f8f7f8"
            >
              <img
                src="../../../assets/images/gamificacao/rei_subespecialidades.png"
                style="max-width: 72px; margin-bottom: 25px;"
              >
              <b-card
                style="width: 100%; margin-bottom: 7px; background-color:#f8f7f8"
                title="Rei das sub-especialidades"
                sub-title="Quem assistiu todas as sub-especialidades"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center"
              style="background-color: #f8f7f8"
            >
              <img
                src="../../../assets/images/gamificacao/mestre_simposios.png"
                style="max-width: 72px; margin-bottom: 25px;"
              >
              <b-card
                style="width: 100%; margin-bottom: 7px; background-color:#f8f7f8"
                title="Mestre dos simpósios"
                sub-title="Quem assistiu todos os simpósios"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center"
              style="background-color: #f8f7f8"
            >
              <img
                src="../../../assets/images/gamificacao/mestre_glaucoma.png"
                style="max-width: 72px; margin-bottom: 25px;"
              >
              <b-card
                style="width: 100%; margin-bottom: 7px; background-color:#f8f7f8"
                title="Mestre conhecedor em Glaucoma"
                sub-title="Concluiu todo conteudo de Glaucoma"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center"
              style="background-color: #f8f7f8"
            >
              <img
                src="../../../assets/images/gamificacao/avatar.png"
                style="max-width: 72px; margin-bottom: 25px;"
              >
              <b-card
                style="width: 100%; margin-bottom: 7px; background-color:#f8f8f8"
                title="Cartão de visita"
                sub-title="Quem completou o perfil completo"
              />
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card
          :img-src="require('@/assets/images/banner/banner-12.jpg')"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar
                size="114"
                variant="light"
                :src="require('@/assets/images/portrait/small/avatar-s-9.jpg')"
              />
            </div>
          </div>
          <h3>Dados insuficientes</h3>
          <b-badge
            class="profile-badge"
            variant="primary"
            style="color: white; font-size: 15px;"
          >
            Atividade mais vista
          </b-badge>
          <hr class="mb-2">
          <div class="row">
            <div class="col cols_subtitle">
              Followers<br><b class="cols_valor">10.0K</b>
            </div>
            <div class="col cols_subtitle">
              Projects<br><b class="cols_valor">156</b>
            </div>
            <div class="col cols_subtitle">
              Rank<br><b class="cols_valor">23</b>
            </div>
          </div>
        </b-card>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { $themeColors } from '@themeConfig'

/* eslint-disable global-require */
const $trackBgColor = '#fff'
export default {
  data() {
    return {
      baseStorage: process.env.VUE_APP_BASE_PUBLIC,
      chartData: [],
      chartColor: [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary],
      chartSeries: [45, 65, 60, 35, 65, 80],
      employeeData: [
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
          userFullName: 'Ryan Harrington',
          designation: 'iOS Developer',
          duration: '9hr 20m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Louisa Norton',
          designation: 'UI Designer',
          duration: '4hr 17m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          userFullName: 'Jayden Duncan',
          designation: 'Java Developer',
          duration: '12hr 8m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Cynthia Howell',
          designation: 'Anguler Developer',
          duration: '3hr 19m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-16.jpg'),
          userFullName: 'Helena Payne',
          designation: 'Marketing',
          duration: '9hr 50m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
          userFullName: 'Troy Jensen',
          designation: 'iOS Developer',
          duration: '4hr 48m',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  computed: {
    ranking() {
      return this.$store.state.ranking.ranking
    },
    userStats() {
      return this.$store.state.ranking.userStats
    },
    user() {
      return this.$store.state.auth.user
    }

  },
  watch: {

  },
  created() {
    for (let i = 0; i < this.employeeData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
  },
  async mounted() {
    await this.ActionGetUserStats()
    await this.ActionGetRanking()
  },
  methods: {
    ...mapActions('ranking', [
      'ActionGetUserStats',
      'ActionGetRanking',
    ]),
    validationImage(url) {
      const path = `${this.baseStorage}/storage/image/${url}`
      const http = new XMLHttpRequest()
      http.open('HEAD', path, false)
      http.send()
      if (http.status === 200 && url) {
        return `${this.baseStorage}/storage/image/${url}`
      }
      return `${this.baseStorage}/public/assets/avatar-1577909.svg`
    },
  }
}
</script>

<style scoped>
span.details {
  font-weight: bold;
}
.card-img{
  height: 100%;
}
.gradient-background {
background: rgb(50,84,187);
background: linear-gradient(90deg, rgba(50,84,187,1) 20%, rgba(62,105,241,1) 43%, rgba(126,155,246,1) 97%);
}
.badge_score {
 max-width: 210px;
 height: 60px;
 width: 100%;
 text-align: left;
 border-radius:10px;
 margin-bottom: 4px;
}
.badge_trasnparent{
background-color: rgba(10,23,55,0);
border: 2px solid white;
}
.big_descrition{
font-size: 38px;
display: inline-block;
margin-top: 5px;
}
.mini_text{
display: inline-block;
margin-left: 6px;
white-space: normal;
margin-top: 5px;
font-weight: 100;
}
.img_score {
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 598px) {
  #div_score {
    text-align: center;
  }
  .gradient-background {
    text-align: center;
  }
}
@media screen and (min-width: 599px) and (max-width: 699px) {
  #div_score{
    float: left;
    max-width: 200px;
  }
  #div_img_score{
    text-align: center;
    max-width: 200px;
    margin-right: 20px;
  }
  .big_descrition{
    font-size: 23px;
    display: inline-block;
    margin-top: 0px;
  }
  #green_descrition{
    padding-top: 15px;
  }
}
@media screen and (min-width: 700px) and (max-width: 780px) {
  #div_score{
    float: left;
    max-width: 200px;
  }
  #div_img_score{
    float: right;
    max-width: 200px;
  }
  .big_descrition{
    font-size: 23px;
    display: inline-block;
    margin-top: 0px;
  }
  #green_descrition{
    padding-top: 15px;
  }
}
@media screen and (min-width: 781px) and (max-width: 1500px) {
  #div_score {
    text-align: center;
  }
  .gradient-background {
    text-align: center;
  }
}
@media screen and (min-width: 1501px) and (max-width: 1860px) {
  #div_score{
    float: left;
    max-width: 200px;
  }
  #div_img_score{
    float: right;
    max-width: 200px;
  }
  .big_descrition{
    font-size: 23px;
    display: inline-block;
    margin-top: 0px;
  }
  #green_descrition{
    padding-top: 15px;
  }
}
@media screen and (min-width: 1861px) {
  #div_score{
    float: left;
  }
  #div_img_score{
    float: right;
  }
}
</style>
